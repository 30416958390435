<template>
    <iframe src="./ResponderDoc.html" style="width:100%;height:calc(100vh - 61px);border:0" ></iframe>
</template>

<script>
    export default {
        name: "Docs"
    }
</script>

<style scoped>

</style>